const metadata = {
    "ga4_page_audience": "HCP",
    "ga4_page_content_purpose": "PRODUCT AWARENESS",
    "ga4_page_customer_journey_stage": "Awareness",
    "ga4_page_customer_specialty": "Oncology",
    "ga4_page_material_intent": "",
    "ga4_page_product": "KEYTRUDA",
    "ga4_page_region": "US",
    "ga4_page_cmsplatform": "S3",
    "ga4_page_cmstheme": "custom",
    "ga4_page_country": "United States",
    "ga4_page_language": "English"
};

const biomarker = {
    ...metadata,
    "ga4_page_content_purpose": "BIOMARKER TESTING",
    "ga4_page_customer_journey_stage": "Consideration",
    "ga4_page_product": "KEYTRUDA - Biomarkers",
};

const dosing = {
    ...metadata,
    "ga4_page_content_purpose": "DOSING AND ADMINISTRATION",
    "ga4_page_customer_journey_stage": "Conversion",
};

const efficacy = {
    ...metadata,
    "ga4_page_content_purpose": "EFFICACY",
    "ga4_page_customer_journey_stage": "Consideration",
};

const adverseEvents = {
    ...metadata,
    "ga4_page_content_purpose": "ADVERSE EVENTS",
    "ga4_page_customer_journey_stage": "Consideration",
};


export const ga4Content = {
    "/efficacy/merkel-cell-carcinoma/": efficacy,
    "/safety/adverse-reactions/merkel-cell-carcinoma/": adverseEvents,
    "/efficacy/advanced-or-recurrent-endometrial-carcinoma/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Endometrial",
    },
    "/safety/adverse-reactions/advanced-or-recurrent-endometrial-carcinoma/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Endometrial",
    },
    "/": metadata,
    "/approved-indications/": metadata,
    "/biomarker-testing/msi-mmr/": biomarker,
    "/biomarker-testing/pd-l1/":biomarker,
    "/biomarker-testing/biomarker-overview/": biomarker,
    "/resources/surgeon-resources/": {
        ...biomarker,
        "ga4_page_customer_specialty": "Surgical Oncology",
        "ga4_page_product": "KEYTRUDA",
        "ga4_page_content_purpose": "RESOURCES AND TOOLS",
    },
    "/contact-us/": {
        ...metadata,
        "ga4_page_content_purpose": "WEB - CONTACT",
        "ga4_page_customer_journey_stage": "Consideration",
    },
    "/dosing/dose-modifications/": dosing,
    "/dosing/options/": dosing,
    "/dosing/preparation-storage-administration/": dosing,
    "/efficacy/": efficacy,
    "/efficacy/adjuvant-renal-cell-carcinoma/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA RCC - Adjuvant",
    },
   "/efficacy/advanced-biliary-tract-cancer-combination-therapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Biliary",
    },
    "/efficacy/advanced-endometrial-carcinoma/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Endometrial",
    },
    "/efficacy/advanced-renal-cell-carcinoma/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA+LENVIMA RCC",
    },
    "/efficacy/cervical-cancer-combination-chemoradiotherapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Cervical",
    },
    "/efficacy/cervical-cancer-first-line-combination-therapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Cervical",
    },
    "/efficacy/cervical-cancer-second-line-monotherapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Cervical",
    },
    "/efficacy/classical-hodgkin-lymphoma/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA cHL",
    },
    "/efficacy/cutaneous-squamous-cell-carcinoma/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA cSCC",
    },
    "/efficacy/early-stage-triple-negative-breast-cancer/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA TNBC",
    },
    "/efficacy/esophageal-carcinoma/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Esophageal",
    },
    "/efficacy/gastric-adenocarcinoma/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Gastric",
    },
    "/efficacy/hnscc-first-line-combination-therapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Head and Neck",
    },
    "/efficacy/hnscc-first-line-monotherapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Head and Neck",
    },
    "/efficacy/melanoma-adjuvant-therapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Melanoma",
    },
    "/efficacy/melanoma-monotherapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Melanoma",
    },
    "/efficacy/msi-h-dmmr/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA CRC MSI-H",
    },
    "/efficacy/msi-h-dmmr-colorectal-cancer/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA CRC MSI-H",
    },
    "/efficacy/nmibc/": {
        ...efficacy,
         "ga4_page_product": "KEYTRUDA NMIBC",
         "ga4_page_customer_specialty": "Urology",
        },
    "/efficacy/nsclc-adjuvant-therapy/": {
        ...efficacy,
         "ga4_page_product": "KEYTRUDA Lung",
        },
    "/efficacy/nsclc-first-line-monotherapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Lung",
    },
    "/efficacy/nsclc-neoadjuvant-therapy/": {
        ...efficacy,
         "ga4_page_product": "KEYTRUDA Lung",
    },
    "/efficacy/nsclc-nonsquamous-first-line-combination-therapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Lung",
    },
    "/efficacy/nsclc-second-line-monotherapy/": {
        ...efficacy,
         "ga4_page_product": "KEYTRUDA Lung",
    },
    "/efficacy/nsclc-squamous-first-line-combination-therapy/": {
        ...efficacy,
         "ga4_page_product": "KEYTRUDA Lung",
    },
    "/efficacy/pmbcl/": {
        ...efficacy, 
        "ga4_page_product": "KEYTRUDA PMBCL",
    },
    "/efficacy/triple-negative-breast-cancer/": {
        ...efficacy,
         "ga4_page_product": "KEYTRUDA TNBC"},
    "/efficacy/urothelial-cancer-first-line-combination-therapy/": {
         ...efficacy,
        "ga4_page_product": "KEYTRUDA Bladder",
        "ga4_page_customer_specialty": "Urology",
    },
    "/efficacy/urothelial-carcinoma-first-line-monotherapy/": {
        ...efficacy,
        "ga4_page_product": "KEYTRUDA Bladder",
        "ga4_page_customer_specialty": "Urology",
    },
    "/efficacy/urothelial-carcinoma-second-line-monotherapy/": {
        ...efficacy, 
        "ga4_page_product": "KEYTRUDA Bladder",
        "ga4_page_customer_specialty": "Urology",
    },
    "/prescribing-information/": {
        ...metadata,
        "ga4_page_customer_journey_stage": "Consideration",
    },
    "/resources/hcp-and-patient-resources/": {
        ...metadata,
        "ga4_page_content_purpose": "RESOURCES AND TOOLS",
        "ga4_page_customer_journey_stage": "Consideration",
    },
    "/resources/key-you-patient-support-program/": {
        ...metadata,
        "ga4_page_content_purpose": "PATIENT SUPPORT PROGRAMS",
        "ga4_page_customer_journey_stage": "Consideration",
    },
    "/resources/mechanism-of-action/": {
        ...metadata,
        "ga4_page_content_purpose": "MECHANISM OF ACTION",
        "ga4_page_customer_journey_stage": "Consideration",
    },
    "/safety/adverse-reactions/": 
    {
    ...adverseEvents,
    },
    "/safety/adverse-reactions/adjuvant-renal-cell-carcinoma/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA RCC - Adjuvant",
    } ,
    "/safety/adverse-reactions/advanced-biliary-tract-cancer-combination-therapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Biliary",
    } ,
    "/safety/adverse-reactions/advanced-endometrial-carcinoma/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Endometrial",

    } ,
    "/safety/adverse-reactions/advanced-renal-cell-carcinoma/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA+LENVIMA RCC",

    } ,
    "/safety/adverse-reactions/cervical-cancer-combination-chemoradiotherapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Cervical",

    } ,
    "/safety/adverse-reactions/cervical-cancer-first-line-combination-therapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Cervical",

    } ,
    "/safety/adverse-reactions/cervical-cancer-second-line-monotherapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Cervical",

    } ,
    "/safety/adverse-reactions/classical-hodgkin-lymphoma/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA cHL",
    } ,
    "/safety/adverse-reactions/cutaneous-squamous-cell-carcinoma/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA cSCC",
    } ,
    "/safety/adverse-reactions/early-stage-triple-negative-breast-cancer/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA TNBC",
    } ,
    "/safety/adverse-reactions/esophageal-carcinoma/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Esophageal",
    } ,
    "/safety/adverse-reactions/gastric-adenocarcinoma/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Gastric",
    } ,
    "/safety/adverse-reactions/hnscc-first-line-combination-therapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Head and Neck",
    } ,
    "/safety/adverse-reactions/hnscc-first-line-monotherapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Head and Neck",
    },
    "/safety/adverse-reactions/melanoma-adjuvant-therapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Melanoma",
    } ,
    "/safety/adverse-reactions/melanoma-monotherapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Melanoma",
    }  ,
    "/safety/adverse-reactions/msi-h-dmmr/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA CRC MSI-H",
    } ,
    "/safety/adverse-reactions/msi-h-dmmr-colorectal-cancer/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA CRC MSI-H",
    } ,
    "/safety/adverse-reactions/nmibc/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA NMIBC",
        "ga4_page_customer_specialty": "Urology",
    } ,
    "/safety/adverse-reactions/nsclc-adjuvant-therapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/nsclc-first-line-monotherapy": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/nsclc-second-line-monotherapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/nsclc-neoadjuvant-therapy": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/nsclc-neoadjuvant-therapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/nsclc-nonsquamous-first-line-combination-therapy": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/nsclc-nonsquamous-first-line-combination-therapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/nsclc-second-line-monotherapy": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "safety/adverse-reactions/nsclc-second-line-monotherapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/nsclc-squamous-first-line-combination-therapy": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/nsclc-squamous-first-line-combination-therapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Lung",
    } ,
    "/safety/adverse-reactions/pmbcl/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA PMBCL",
    } ,
    "/safety/adverse-reactions/triple-negative-breast-cancer/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA TNBC",
    } ,
    "/safety/adverse-reactions/urothelial-cancer-first-line-combination-therapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Bladder",
        "ga4_page_customer_specialty": "Urology",
    } ,
    "/safety/adverse-reactions/urothelial-carcinoma-first-line-monotherapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Bladder",
        "ga4_page_customer_specialty": "Urology",
    } ,
    "/safety/adverse-reactions/urothelial-carcinoma-second-line-monotherapy/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA Bladder",
        "ga4_page_customer_specialty": "Urology",
    } ,
    "/safety/monitoring-managing-adverse-reactions/": {
        ...adverseEvents,
        "ga4_page_product": "KEYTRUDA",
    },
    "/sitemap/": {
        ...metadata,
    "ga4_page_content_purpose": 'WEB - SITE MAP AND NAVIGATION',
    },
    "/404/": {
        ...metadata,
        "ga4_page_content_purpose": 'WEB - SITE MAP AND NAVIGATION',
    },
}